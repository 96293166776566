export function base64urldecode(input) {
  input = input.replace(/-/g, "+");
  input = input.replace(/_/g, "/");

  const pad = input.length % 4;
  if (pad > 0) {
    input += new Array(5 - pad).join("=");
  }

  return atob(input);
}

export function bytestringToBuffer(string) {
  const bytes = new Array(string.length);
  for (var i = 0; i < string.length; i++) {
    bytes[i] = string.charCodeAt(i);
  }
  const array = new Uint8Array(bytes);
  return array.buffer;
}
