export class CryptoPlain {
  static async init() {
    return new CryptoPlain();
  }

  static async deserialize() {
    return await CryptoPlain.init();
  }

  async decryptData(data) {
    return data;
  }

  async decryptMetadata(metadata) {
    return metadata;
  }

  serialize() {
    return {};
  }
}
