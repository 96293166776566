import { CryptoV1 } from "../../lib/crypto/v1";
import { CryptoPlain } from "../../lib/crypto/plain";
import { VideoMedia } from "../../lib/media/video";

const logEl = document.getElementById("log");
function log(t) {
  logEl.textContent += String(t) + "\n";
  if (logEl.textContent.split("\n").length > 30) {
    logEl.textContent = logEl.textContent.split("\n").splice(1).join("\n");
  }
}

log(crypto);
log(crypto.subtle);

let media = null;
ShakaReceiverApp.prototype.appDataCallback_ = async function (appData) {
  if (!appData) {
    return;
  }
  log("got appdata");
  log(JSON.stringify(appData));

  try {
    const cryptoInfo = appData["crypto"];
    let crypto;
    if (cryptoInfo["version"] === "v1") {
      crypto = await CryptoV1.deserialize(cryptoInfo["serialized"]);
    } else if (cryptoInfo["version"] === "plain") {
      crypto = await CryptoPlain.deserialize(cryptoInfo["serialized"]);
    } else {
      throw new Error("unsupported crypto " + cryptoInfo["version"]);
    }

    log("done setting crypto");

    const mediaInfo = appData["media"];
    media = await VideoMedia.deserialize(mediaInfo, crypto);
  } catch (e) {
    log(e);
    log(e.message);
    log(e.stack);
  }

  log("done setting media");
  log(media);
};

ShakaReceiverApp.prototype.init = function () {
  const video = document.getElementById("video");
  goog.asserts.assert(video instanceof HTMLVideoElement, "Wrong element type!");
  this.video_ = video;

  const ui = this.video_["ui"];
  goog.asserts.assert(
    ui instanceof shaka.ui.Overlay,
    "UI not present or wrong type!"
  );

  // Make sure we don't show extra UI elements we don't need on the TV.
  ui.configure({
    fadeDelay: 3,
    addBigPlayButton: false,
    addSeekBar: true,
    controlPanelElements: ["play_pause", "time_and_duration", "spacer"],
  });

  // We use the UI library on both sender and receiver, to get a consistent UI
  // in both contexts.  The controls, therefore, have both a proxy player
  // (getPlayer) and a local player (getLocalPlayer).  The proxy player is
  // what the sender uses to send commands to the receiver when it's casting.
  // Since this _is_ the receiver, we use the local player (local to this
  // environment on the receiver).  This local player (local to the receiver)
  // will be remotely controlled by the proxy on the sender side.
  this.player_ = ui.getControls().getLocalPlayer();
  goog.asserts.assert(this.player_, "Player should be available!");

  const RequestType = shaka.net.NetworkingEngine.RequestType;
  this.player_
    .getNetworkingEngine()
    .registerResponseFilter(async function (type, response) {
      log(type + " " + response.uri);
      if (type == RequestType.MANIFEST || type == RequestType.SEGMENT) {
        log("before decryption");
        try {
          response.data = await media.decryptFile(response.uri, response.data);
        } catch (e) {
          log(e);
          log(e.message);
          log(e.stack);
        }
        log("after decryption");
      }
    });

  this.idleCard_ = document.getElementById("idle");

  this.receiver_ = new shaka.cast.CastReceiver(
    this.video_,
    this.player_,
    (appData) => this.appDataCallback_(appData)
  );
  this.receiver_.addEventListener("caststatuschanged", () => this.checkIdle_());

  this.startIdleTimer_();
};
