export class VideoMedia {
  constructor(baseUrl, playlist, filename, crypto, serialized) {
    this.baseUrl = baseUrl;
    this.playlist = playlist;
    this.filename = filename;
    this.crypto = crypto;
    this.serialized = serialized;
  }

  static async init(metadata, baseUrl, crypto) {
    return new VideoMedia(
      baseUrl,
      metadata["playlist"],
      metadata["filename"],
      crypto,
      { metadata, baseUrl }
    );
  }

  serialize() {
    return this.serialized;
  }

  static async deserialize(data, crypto) {
    return await VideoMedia.init(data["metadata"], data["baseUrl"], crypto);
  }

  getPlaylistUrl() {
    return `${this.baseUrl}/${this.playlist}`;
  }

  async decryptFile(url, data) {
    const path = url.substring(this.baseUrl.length + 1);
    return await this.crypto.decryptData(data, path);
  }
}
