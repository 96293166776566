import { bytestringToBuffer, base64urldecode } from "./util";

const aesKeySize = 16;

export class CryptoV1 {
  constructor(metadataKey, metadataNonce, contentKey, serialized) {
    this.metadataKey = metadataKey;
    this.metadataNonce = metadataNonce;
    this.contentKey = contentKey;
    this.serialized = serialized;
  }

  static async init(encodedSecretKey, publicMetadata) {
    const secretKey = await crypto.subtle.importKey(
      "raw",
      bytestringToBuffer(base64urldecode(encodedSecretKey)),
      { name: "HKDF" },
      false,
      ["deriveKey"]
    );
    const metadataKey = await CryptoV1.deriveDecryptionKey(
      secretKey,
      "metadata",
      "AES-GCM"
    );
    const contentKey = await CryptoV1.deriveDecryptionKey(
      secretKey,
      "content",
      "AES-CBC"
    );
    return new CryptoV1(
      metadataKey,
      bytestringToBuffer(atob(publicMetadata["metadata_nonce"])),
      contentKey,
      { encodedSecretKey, publicMetadata }
    );
  }

  static async deserialize(data) {
    return await CryptoV1.init(
      data["encodedSecretKey"],
      data["publicMetadata"]
    );
  }

  serialize() {
    return this.serialized;
  }

  static async deriveDecryptionKey(secretKey, keyName, algorithm) {
    return await crypto.subtle.deriveKey(
      {
        name: "HKDF",
        hash: "SHA-256",
        salt: new ArrayBuffer(0),
        info: new TextEncoder().encode(keyName),
      },
      secretKey,
      { name: algorithm, length: aesKeySize * 8 },
      false,
      ["decrypt"]
    );
  }

  async calculateContentIV(filePath) {
    const input = new TextEncoder().encode(filePath);
    const buffer = await crypto.subtle.digest("SHA-256", input);
    return buffer.slice(0, aesKeySize);
  }

  async decryptData(data, filePath) {
    const iv = await this.calculateContentIV(filePath);
    return await crypto.subtle.decrypt(
      { name: "AES-CBC", iv },
      this.contentKey,
      data
    );
  }

  async decryptMetadata(metadata) {
    return await crypto.subtle.decrypt(
      { name: "AES-GCM", iv: this.metadataNonce },
      this.metadataKey,
      metadata
    );
  }
}
